// Here you can add other styles

.azslogo {
  font-size: 30px !important;
  font-family: "Segoe Semibold", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: normal;
  color: #0078d4 !important;
  align-items: center;
  vertical-align: middle !important;
  text-decoration: none !important;
}

.spacer5 {
  height: 5px;
}

.spacer20 {
  height: 20px;
}

.spacer50 {
  height: 50px;
}

// Customer detail page

.headerLink > a {
  color: white !important;
}

/* .c-body {
  background-color: white;
} */

.customerDetails__solutions__description {
  line-height: 1.6;
}

.customerDetails__customerBoxLogo > img {
  object-fit: contain;
  height: 41px;
  width: 151px;
}

.customerDetails__customerBoxLogo {
  padding-top: 12px;
  padding-bottom: 12px;
}

.customerDetails__customerBox {
  border: 1px solid #ebebeb;
  border-radius: 8px;
  background: #fff;
  /* width: 457px; */
}

.customerDetails__customerBoxCustomerNameType {
  font-weight: normal;
  font-family: Google Sans, arial, sans-serif !important;
  color: rgba(0, 0, 0, 0.87);
  line-height: 1.2;
}

.customerDetails__customerBoxCustomerName {
  font-size: 25px;
  color: rgba(0, 0, 0, 0.87);
  text-align: left;
}

.customerDetails__customerBoxCustomerNameType {
  color: #70757a;
  line-height: 1.58;
  text-align: left;
}

.customerDetails__customerBoxDescription {
  padding-left: 15px;
  padding-right: 15px;
  margin: 13px 0 13px;
  color: #4d5156;
  line-height: 1.58;
  font-size: 14px;
  font-family: arial, sans-serif;
  text-align: justify;
}

.customerDetails__customerBoxDescription > p {
  margin-top: 12px;
  margin-bottom: 12px;
}

.customerDetails__customerBoxHeader {
  justify-content: space-between;
  display: flex;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  overflow: hidden;
  align-items: center;
  min-height: 120px;
  border-bottom: 1px solid #ebebeb;
  padding-left: 15px;
  padding-right: 15px;
}

.spacer20 {
  height: 20px;
}

.spacer50 {
  height: 50px;
}

.incident {
  margin: 5px;
}

.noBorder {
  border: 0px;
  padding:0; 
  margin:0;
  border-collapse: collapse;
}

// S360 Accessibility Changes

.text-muted {
  color: #737373 !important;
}

// Sidebar

.c-sidebar {
  color: #fff;
  background: #2f2f2f;
}

.c-sidebar .c-sidebar-nav-link:hover, .c-sidebar .c-sidebar-nav-dropdown-toggle:hover {
  color: #fff;
  background: #737373;
}

.c-sidebar .c-sidebar-nav-link.c-active, .c-sidebar .c-active.c-sidebar-nav-dropdown-toggle {
  color: #fff;
  background: rgba(255, 255, 255, 0.05);
}

// Misc

.c-app {
  color: #274b47;
  --color: #274b47;
}

code {
  font-size: 87.5%;
  color: #737373;
  word-wrap: break-word;
}

// Footer

.c-footer {
  color: #3c4b64;
  background: #fff;
  border-top: 1px solid #d2d2d2;
}

// Forms

.input-group-text {
  color: #d2d2d2;
  background-color: #ebedef;
  border-color: #d2d2d2;
}

.form-control {
  color: #000 !important;
  background-color: #fff;
  border-color: #d2d2d2;
}

.form-control::-webkit-input-placeholder {
  color: #737373;
  opacity: 100;
}

.form-control::-moz-placeholder {
  color: #737373;
  opacity: 100;
}

.form-control:-ms-input-placeholder {
  color: #737373;
  opacity: 100;
}

.form-control::-ms-input-placeholder {
  color: #737373;
  opacity: 100;
}

.form-control::placeholder {
  color: #737373;
  opacity: 100;
}

// Header

.c-header .c-header-nav .c-header-nav-link, .c-header .c-header-nav .c-header-nav-btn {
  color: #737373;
}

.c-header .c-header-nav .c-header-nav-link:hover, .c-header .c-header-nav .c-header-nav-link:focus, .c-header .c-header-nav .c-header-nav-btn:hover, .c-header .c-header-nav .c-header-nav-btn:focus {
  color: #2f2f2f;
}

.breadcrumb-item.active {
  color: #737373;
}

.c-subheader .c-subheader-nav .c-subheader-nav-link, .c-subheader .c-subheader-nav .c-subheader-nav-btn {
  color: #737373;
}

.c-subheader .c-subheader-nav .c-subheader-nav-link:hover, .c-subheader .c-subheader-nav .c-subheader-nav-link:focus, .c-subheader .c-subheader-nav .c-subheader-nav-btn:hover, .c-subheader .c-subheader-nav .c-subheader-nav-btn:focus {
  color: #2f2f2f;
}

// Tables

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #505050;
}

// Button

.btn-secondary {
  color: #505050;
  background-color: #d2d2d2;
  border-color: #d2d2d2;
}

// Nav Links (EXPO Page)

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
  color: #505050;
  background-color: #e6e6e6;
  border-color: #d2d2d2 #d2d2d2 #e6e6e6;
}

.nav-tabs .nav-link {
  border: 1px solid transparent;
  background-color: #fff;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  border-color: #d2d2d2 #d2d2d2 #e6e6e6;
}

.min-width-500-col {
  min-width: 500px;
}