// Variable overrides

$blue: #0078d4;
$orange: #d83b01;
$yellow: #ffb900; 
$green: #107c10;
$teal: #008575;
$cyan: #17a2b8;
$gray: #d2d2d2;
$gray-dark: #505050;

$primary: #0078d4;
$secondary: #274b47;
$success: #107c10;
$info: #008575;
$warning: #ffb900;
$danger: #d83b01;
$light: #f2f2f2;
$dark: #2f2f2f;

$font-family-sans-serif: 'Segoe UI',-apple-system,BlinkMacSystemFont,Roboto,Oxygen,Ubuntu,Cantarell,'Open Sans','Helvetica Neue',sans-serif;
