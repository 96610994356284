.toaster-body {
  padding: 0.5rem;
  font-family: "Calibri";
}
.toaster-area {
  z-index: 9999;
  height: 80vh;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  position: absolute;
  right: 10px;
  top: 5px;
}
